import * as React from 'react'
import {graphql} from 'gatsby'
import {css, Global} from '@emotion/core'
import styled from '@emotion/styled'
import {Helmet} from 'react-helmet'
import Ping from 'ping.js'

import {SwiggleSearch} from '../components/swiggle'

interface DefaultTemplateQuery{
  data: {
    school: {
      name: string
      body: string
      background: {
        localFile: {
          childImageSharp: {
            fixed: {
              src: string
            }
          }
        }
      }
      links: {
        title: string
        colour: string
        address: string
      }[]
    }
  }
}

const Container = styled('div')`
  width:800px;
  margin:auto;
  margin-top:35vh;
  background-color:rgba(255,255,255,0.4);
  box-sizing:border-box;
  font-family: 'Roboto';

  a{
    text-decoration:none;
  }

  h1{
    margin:10px 20px;
  }
`

const Links = styled('div')`
  overflow:auto;
`

const Link = styled<'a', {color: string}>('a')`
  float:left;
  width:200px;
  text-align:center;
  display:block;
  color:#fff;
  background-color:${(props) => props.color};
  line-height:5vh;
`

const Content = styled('div')`
  padding:20px;
  
  div{
    overflow-wrap:break-word;
  }
`

const Flash = styled('div')`
  width:800px;
  margin:auto;
  position:absolute;
  top:20px;
  background-color:rgba(240,0,0,0.5);
  box-sizing: border-box;
`

class DefaultTemplate extends React.Component<DefaultTemplateQuery, {tested: boolean, active: boolean}>{
  constructor(props: DefaultTemplateQuery){
    super(props)

    this.state = {
      tested: false,
      active: false
    }
  }

  componentDidMount(){
    const p = new Ping({
      favicon: '/assets/logo.png'
    })

    p.ping('https://homepages.ed-it.solutions', (err) => {
      console.dir(err)
      this.setState({
        tested: true,
        active: (err === null)
      })
    })
  }

  render(){
    const {school} = this.props.data

    return <Container>
      <Global styles={css`
        body{
          background-image:url(${school.background.localFile.childImageSharp.fixed.src});
          background-position:center;
          background-attachment:fixed;
        }
      `} />
      {(this.state.tested && !this.state.active) ? <Flash>
        <Content>
          Looks like your internet connection is down. If you experiance problems using the internet in school please contact our office on 01524771950.
        </Content>
      </Flash> : ''}
      <Helmet title={school.name} />
      <h1>{school.name}</h1>
      <Content>
        <SwiggleSearch font="Roboto" />
      </Content>
      <Links>
        {school.links.map((link, i) => (
          <Link href={link.address} color={link.colour} key={i}>{link.title}</Link>
        ))}
      </Links>
      <Content dangerouslySetInnerHTML={{__html: school.body}} />
      <Content>
        <i>Home Page provided by <a href="https://www.ed-itsolutions.com">Ed-IT Solutions</a></i>
      </Content>
    </Container>
  }
}

export default DefaultTemplate

export const query = graphql`
  query DefaultSwiggleTemplateQuery($slug: String!){
    school: directusHomepage(slug: {eq: $slug}){
      name
      body
      background{
        localFile{
          childImageSharp{
            fixed(width: 1920, height: 1080, quality: 100){
              src
            }
          }
        }
      }
      links {
        title
        colour
        address
      }
    }
  }
`