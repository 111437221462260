import * as React from 'react'
import styled from '@emotion/styled'

const SearchForm = styled('form')`
  
`

const SearchBox = styled.input<{font: string}>`
  width:100%;
  padding:5px;
  font-family:'${(props) => props.font}';
  box-sizing:border-box;
  line-height:30px;
  background-image:url(https://swiggle.org.uk/graphics/swiggle.svg);
  background-repeat:no-repeat;
  background-position:5px 3px;
  background-size:100px;
  border:1px solid #ccc;
  padding-left:110px;
`

const SearchButton = styled.input`
  position:absolute;
  right:5px;
  top:5px;
  line-height:30px;
  border-radius:2px;
  border:1px solid #ccc;
`

const Container = styled.div`
  position:relative;
`

export const SwiggleSearch: React.SFC<{font: string}> = ({font}) => (
  <Container>
    <SearchForm method="get" action="https://swiggle.org.uk/">
      <SearchBox name="q" type="text" font={font} />
      <SearchButton type="submit" value="Swiggle Search" />
    </SearchForm>
  </Container>
)